export default function titleCase(str: string) {
	if (!str) return ''
	return str
		.toLowerCase()
		.split('_')
		.filter(Boolean) // Remove empty strings from multiple underscores
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export function titleCaseSpace(str: string) {
	if (!str) return ''
	// Split by space but keep the spaces using capture groups
	return str
		.split(/( +)/)
		.map((part) => {
			// If it's just spaces, return as is
			if (part.trim() === '') return part
			// If the word is already uppercase, preserve it
			if (part === part.toUpperCase()) return part
			// Otherwise, capitalize first letter
			return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
		})
		.join('')
}

export function titleCaseDash(str: string) {
	if (!str) return ''
	return str
		.toLowerCase()
		.split('-')
		.filter(Boolean) // Remove empty strings from multiple dashes
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export function camelCaseFromSnake(str: string) {
	if (!str) return ''
	const words = str.toLowerCase().split('_').filter(Boolean) // Remove empty strings from multiple underscores

	if (words.length === 0) return ''

	return (
		words[0].toLowerCase() +
		words
			.slice(1)
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join('')
	)
}

export function fixTitleCharacters(str: string | null | undefined) {
	if (!str) return ''
	return str
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&#39;/g, "'")
		.replace(/&#34;/g, '"')
		.replace(/&quot;/g, '"')
}
